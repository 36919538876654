import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/radar';
import './index.css';
let questions = require('./question.json');
questions.sort(function () {
  return (0.5 - Math.random());
})

let myResultTab = null;
const tabFormateRs = [
  {
    cateKey: 'R',
    cateName: '实际型',
    score: 0,
    type: '家里你说了算',
    info: '愿意使用工具从事操作性工作，动手能力强，做事手脚灵活，动作协调。偏好于具体任务，不善言辞，做事保守，较为谦虚。缺乏社交能力，通常喜欢独立做事。',
    carrer:"喜欢使用工具、机器，需要基本操作技能的工作。对要求具备机械方面才能、体力或从事与物件、机器、工具、运动器材、植物、动物相关的职业有兴趣，并具备相应能力。如：计算机硬件人员、摄影师、制图员、机械操作人员、医生、木匠、厨师等。",
    major:"适合/建议工学门类，临床医学类，口腔医学类，中西医结合类，法医学类（以上专业同时考察I），医学技术类，教育技术学，武术与民族传统体育，文物保护技术，历史建筑保护工程，物流工程"
  },
  {
    cateKey: 'C',
    cateName: '常规型',
    score: 0,
    type: '非常细心的人，另一半很幸福',
    info: '尊重权威和规章制度，喜欢按计划办事，细心、有条理，习惯接受他人的指挥和领导，自己不谋求领导职务。喜欢关注实际和细节情况，通常较为谨慎和保守，缺乏创造性，不喜欢冒险和竞争，富有自我牺牲精神。',
    carrer:"喜欢要求注意细节、精确度、有系统有条理，具有记录、归档、据特定要求或程序组织数据和文字信息的职业，并具备相应能力。如：秘书、办公室人员、记事员、会计、行政助理、图书馆管理员。",
    major:"适合/建议财政学类，图书情报与档案管理类，工业工程类，秘书学，编辑出版学，数字出版，工程造价，会计学，财务管理，审计学，资产评估，财务会计教育"
  },
  {
    cateKey: 'E',
    cateName: '企业型',
    score: 0,
    type: '你就是传说中的领导',
    info: '追求权力、权威和物质财富，具有领导才能。喜欢竞争、敢冒风险、有野心、抱负。为人务实，习惯以利益得失，权利、地位、金钱等来衡量做事的价值，做事有较强的目的性。',
    carrer:"喜欢要求具备经营、管理、劝服、监督和领导才能，以实现机构、政治、社会及经济目标的工作，并具备相应的能力。如项目经理、销售人员，营销管理人员、政府官员、企业领导、法官、律师。",
    major:"适合/建议法学类、公安学类，农业经济管理类，公共管理类，电子商务类，旅游管理类，外交学、工程管理，房地产开发与管理，工商管理，市场营销，国际商务，人力资源管理，文化产业管理，劳动关系，体育经济与管理，市场营销教育，物流管理，采购管理"
  },
  {
    cateKey: 'S',
    cateName: '社会型',
    score: 0,
    type: '没办法，你到处都有朋友',
    info: '喜欢与人交往、不断结交新的朋友、善言谈、愿意教导别人。关心社会问题、渴望发挥自己的社会作用。寻求广泛的人际关系，比较看重社会义务和社会道德。',
    carrer:"喜欢要求与人打交道的工作，能够不断结交新的朋友，从事提供信息、启迪、帮助、培训、开发或治疗等事务，并具备相应能力。如：教育工作者（教师、教育行政人员），护理人员，社会工作者（咨询人员、公关人员）等。",
    major:"适合/建议外国语言文学类，护理学类，社会工作，家政学，科学教育，人文教育，学前教育、小学教育、特殊教育、华文教育、教育康复学，体育教育，运动训练，社会体育指导与管理，运动康复，休闲体育，汉语国际教育，新闻学，广播电视学，网络与新媒体，应用心理学，物业管理"
  },
  {
    cateKey: 'A',
    cateName: '艺术型',
    score: 0,
    type: '没错，艺术家就该留长发',
    info: '有创造力，乐于创造新颖、与众不同的成果，渴望表现自己的个性，实现自身的价值。做事理想化，追求完美，不重实际。具有一定的艺术才能和个性。善于表达、怀旧、心态较为复杂。',
    carrer:"喜欢的工作要求具备艺术修养、创造力、表达能力和直觉，并将其用于语言、行为、声音、颜色和形式的审美、思索和感受，具备相应的能力，不善于事务性工作。如艺术方面（演员、导演、艺术设计师、雕刻家、建筑师、摄影家、广告制作人），音乐方面（歌唱家、作曲家、乐队指挥），文学方面（小说家、诗人、剧作家）。",
    major:"适合/建议艺术教育，汉语言文学，广告学，工业设计，服装设计与工程，服装设计与工艺教育，建筑学，风景园林"
  },
  {
    cateKey: 'I',
    cateName: '研究型',
    score: 0,
    type: '很牛逼（除了头发）',
    info: '思想家而非实干家,抽象思维能力强，求知欲强，肯动脑，善思考，不愿动手。喜欢独立的和富有创造性的工作。知识渊博，有学识才能，不善于领导他人。考虑问题理性，做事喜欢精确，喜欢逻辑分析和推理，不断探讨未知的领域。',
    carrer:"喜欢智力的、抽象的、分析的、独立的定向任务，要求具备智力或分析才能，并将其用于观察、估测、衡量、形成理论、最终解决问题的工作，并具备相应的能力。如科学研究人员、工程师等。",
    major:"适合/建议哲学门类，历史学门类，理学门类，农学门类（理学、农学门类专业同时考察R），经济学类，金融学类，经济与贸易类，民族学类，马克思主义理论类，基础医学类，公共卫生与预防医学类，中医学类，药学类，中药学类，政治学与行政学，国际政治，国际事务与国际关系，政治学、经济学与哲学，社会学，人类学，女性学，犯罪学，教育学，运动人体科学，汉语言，中国少数民族语言文学，古典文献学，应用语言学，传播学，城乡规划，管理科学，信息管理与信息系统，保密管理"
  }
];
let tabFormateConfig = {
  radar: {
    name: {
      textStyle: {
        color: '#333333'
      }
    },
    radius: '70%',
    indicator: tabFormateRs.map(single => {
      return {
        name: single.cateKey+single.cateName,
        max: 10
      }
    })
  }
}

// 头部
const Header = (props) => {
  return (
    <header>霍兰德职业兴趣测试</header>
  )
}

// 介绍
const Introduce = (props) => {
  return (
    <div className={props.show ? 'introduce' : 'hide'}>霍兰德职业适应性测验(The Self-Directed Search，简称SDS)由美国著名职业指导专家 Ｊ.霍兰德（ＨＯＬＬＡＮＤ）编制。在几十年间经过一百多次大规模的实验研究，形成了人格类型与职业类型的学说和测验。 该测验能帮助被试者发现和确定自己的职业兴趣和能力专长, 从而科学地做出求职择业。</div>
  )
}

// 测试题目
class Question extends Component {
  constructor() {
    super();
    this.state = {
      questions: questions,
      no: 1,
      nums: questions.length,
      myResults: {}
    }
  }

  handleAnswer(answer) {
    let no = this.state.no,
      question = this.state.questions[this.state.no - 1],
      myResults = this.state.myResults;
    if (no >= this.state.nums) {
      // 问题回答结束
      this.props.finishQuestion(myResults);
      this.setState({
        no: 1
      })
    } else {
      if (!myResults[question.cateKey]) {
        myResults[question.cateKey] = 0
      }
      myResults[question.cateKey] += answer === question.score ? 1 : 0
      this.setState({
        no: ++no,
        myResults: myResults
      })
    }
  }

  render() {
    return (
      <div className={this.props.show ? 'question' : 'hide'}>
        <div className="questionNo"><span className="no">{this.state.no}</span><span className="nums"> / {this.state.nums}</span></div>
        <p className="title">{this.state.questions[this.state.no - 1].title}</p>
        <p className="action">
          <span className="button" onClick={this.handleAnswer.bind(this, 1)}>是的</span>
          <span className="button" onClick={this.handleAnswer.bind(this, 0)}>不是</span>
        </p>

      </div>
    )
  }
}

// 测试结果
class Result extends Component {
  constructor() {
    super()
    this.state = {
      rs: '待测试',
      type: '还没有分析',
      info: ''
    }
  }

  // 初始化图表
  componentDidMount() {
    if (!myResultTab) {
      myResultTab = echarts.init(document.getElementById('myResultTab'));
    }
  }
  componentWillReceiveProps(props) {
    // 画图
    if (props.results) {
      this.resultAnalysis(props.results)
    }
  }
  // 测试结果分析
  resultAnalysis(results) {
    let tempTypes = tabFormateRs.map(single => {
      single.score = results[single.cateKey] || single.score
      return single
    });
    let rsData = tempTypes.concat();
    // 查询前三的结果
    rsData.sort((x, y) => {
      if (x.score > y.score) {
        return -1
      } else if (x.score < y.score) {
        return 1;
      } else {
        return 0;
      }
    })
    this.setState({
      rs: rsData[0].cateKey + rsData[1].cateKey + rsData[2].cateKey,
      type: rsData[0].type,
      info: rsData[0].info,
      carrer:rsData[0].carrer,
      major:rsData[0].major
    })

    tabFormateConfig.series = [{
      type: 'radar',
      data: [
        {
          value: tempTypes.map(single => single.score),
          symbol: 'circle',
          symbolSize: 3,
          label:{
            normal:{
              show:true,
              formatter:function(params){
                return params.value;
              }
            }
          },
          lineStyle: {
              normal: {
                  color: '#ea8973'
              }
          },
          areaStyle: {
            normal: {
                opacity: 0.7,
                color: '#ea8973'
            }
          }
        }
      ]
    }];
    myResultTab.setOption(tabFormateConfig)
  }

  render() {
    return (
      <div className={this.props.show ? 'result' : 'hide'}>
        <span className="rs">您的霍兰德类型是：{this.state.rs}</span>
        <div id="myResultTab"></div>
        <div className="info">
          <span className="title">结果解读</span>
          <p>
            <span className="type">{this.state.type}</span>
            <br/>
            {this.state.info}
          </p>
        </div>
        <div className="info">
          <span className="title">典型职业</span>
          <p>
            {this.state.carrer}
          </p>
        </div>
        <div className="info">
          <span className="title">专业推荐</span>
          <p>
            {this.state.major}
          </p>
        </div>
      </div>
    )
  }
}

// 尾部
const Footer = (props) => {
  return (
    <footer>©2021 龙德传仁志愿咨询</footer>
  )
}


class MkTest extends Component {
  constructor() {
    super()
    this.state = {
      isStarted: false,
      isFinished: false
    }
  }

  startTest() {
    this.setState({
      isStarted: true
    });
  }

  finishQuestion(results) {
    this.setState({
      isFinished: true,
      results: results
    });
  }

  render() {
    return (
      <div className="mktest">
        <Header />
        <div className="testBody">
          <Introduce show={!this.state.isStarted} />
          <span className={!this.state.isStarted ? 'startButton' : 'hide'} onClick={this.startTest.bind(this)}>开始测试</span>
          <Question show={this.state.isStarted && !this.state.isFinished} finishQuestion={this.finishQuestion.bind(this)} />
          <Result show={this.state.isFinished} results={this.state.results} />
        </div>
        <Footer />
      </div>
    )
  }
}

ReactDOM.render(
  <MkTest />,
  document.getElementById('root')
)
